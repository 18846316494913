@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@200;400;500;600;700;900&display=swap");

body {
  margin: 0;
  font-family: "Mulish";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-table-container {
  font-family: "Mulish";
}

.ant-table-tbody > tr > td {
  /* cursor: pointer; */
}
