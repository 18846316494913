.navbar {
  @extend .flexbox;
  width: 250px;
  height: calc(100vh);
  padding: 10px 20px;
  background-color: white;
  overflow-y: auto;
  flex-direction: column;
  flex-shrink: 0;
  position: absolute;

  .top-section {
    @extend .flexbox;
    // gap: 10px;
    align-items: center;
    justify-content: space-between;
  }

  .nav-links {
    @extend .flexbox;
    flex-direction: column;

    // justify-content: center;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    .nav-item {
      @extend .flexbox;
      gap: 10px;
      // align-items: center;
      // justify-content: space-between;
      width: 100%;

      padding: 5px 10px;
      margin: 5px 0px;
      border-radius: 5px;
      background-color: transparent;
      cursor: pointer;
      text-decoration: none;
      color: red; //$primary-color;
      font-weight: 600;
      font-size: 17px;

      &:hover {
        background-color: #f36a2f4a;
      }

      &.active {
        background-color: #f36a2f4a;
        // color: white;
      }
    }
  }

  .user-card {
    @extend .flexbox;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }
}
